import React, {useState, useEffect} from "react";
import {Accordion, Container, Dimmer, Grid, Icon, Loader, Message} from "semantic-ui-react";
import AssetInfo from "./AssetInfo";

export default function TokenAccordionPanel(props) {
    const [tokenAssets, setTokenAssets] = useState([]);

    useEffect(function getTokenAssets() {
        if (props.tokenAdminResponse) {
            console.log("(ConsensoAccordionPanel.getTokenAssets) token response: ", props.tokenAdminResponse);
            setTokenAssets(props.tokenAdminResponse);
        } else {
            setTokenAssets([]);
            props.setTokenError(true);
        }
    }, [props.tokenAdminResponse]);

    return (
        <Accordion.Panel
            onTitleClick={props.onTitleClick}
            active={props.active}
            title="Token"
            as={Container}
            content={
                <Accordion.Content>
                    <Dimmer.Dimmable dimmed={props.loading}>
                        {
                            props.loading ? <p><br /><br /><br /><br /></p> : ""
                        }
                        <Dimmer active={props.loading}><Loader indeterminate>Querying Token Admin</Loader></Dimmer>
                        <Grid>
                            <Grid.Row textAlign="left">
                                {
                                    tokenAssets.length < 1 ?
                                        <Grid.Column width={16} textAlign="left">
                                            <Message color="green" icon="info" header="Results" content={`No assets were found in Token with the mediaId ${props.mediaId}.`} />
                                        </Grid.Column> :
                                    props.tokenError ?
                                        <Grid.Column width={16} textAlign="left">
                                            <Message error icon="exclamation" header="Error" content="There was an error querying Token Service." />
                                        </Grid.Column> : ""
                                }
                                <Grid.Column width={16} textAlign="left">
                                    {
                                        tokenAssets.map((asset, index) => {
                                            return <AssetInfo
                                                key={`token-asset-${index}`}
                                                id={asset._id}
                                                flights={asset.flights}
                                                assetIds={asset.assetIds}
                                                setError={props.setTokenError}
                                                brand={asset.brand}
                                                lastUpdate={asset.updateTime}
                                                dateTimeFormat={props.dateTimeFormat}
                                            />
                                        })
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Dimmer.Dimmable>
                </Accordion.Content>
            }
        />
    );
}
