import React, {useState, useEffect} from "react";
import {Accordion, Container, Dimmer, Grid, Loader, Message, Table} from "semantic-ui-react";

export default function ProductCatalogAccordionPanel(props) {
    const [productEntitlementMap, setProductEntitlementMap] = useState({});

    useEffect(function onResponseUpdate() {
        console.log("ProductCatalogAccordionPanel.onResponseUpdate: got response", props.productCatalogResponse);
        const updatedProductEntitlementMap = {};

        if (props.productCatalogResponse) {
            for (const product of props.productCatalogResponse) {
                const upi = product.universalProductIndicator;
                console.log("(ProductCatalogAccordionPanel.onResponseUpdate) adding entitlements for ", upi);
                const entitlements = new Set();
                for (const entitlement of product.entitlements) {
                    console.log("(ProductCatalogAccordionPanel.onResponseUpdate) adding entitlement from root: ", entitlement.entitlement);
                    entitlements.add(entitlement.entitlement);
                }

                for (const storefront of Object.keys(product.storefrontProducts)) {
                    console.log("(ProductCatalogAccordionPanel.onResponseUpdate) checking storefront ", storefront);
                    for (const storefrontProduct of product.storefrontProducts[storefront]) {
                        for (const entitlement of storefrontProduct.entitlements) {
                            console.log("(ProductCatalogAccordionPanel.onResponseUpdate) adding entitlement from storefront product: ", entitlement.entitlement);
                            entitlements.add(entitlement.entitlement);
                        }
                    }
                }

                updatedProductEntitlementMap[upi] = Array.from(entitlements);
            }
        }

        setProductEntitlementMap(updatedProductEntitlementMap);
        console.log("ProductCatalogAccordionPanel.onResponseUpdate: product entitlement map: ", updatedProductEntitlementMap);
    }, [JSON.stringify(props.productCatalogResponse)]);

    return (
        <Accordion.Panel
            onTitleClick={props.onTitleClick}
            active={props.active}
            title="Product Catalog"
            as={Container}
            content={
                <Accordion.Content>
                    <Dimmer.Dimmable dimmed={props.loading}>
                        <Grid>
                            <Grid.Row textAlign="left">
                                <Grid.Column>
                                    {
                                        props.loading ? <p><br /><br /><br /><br /></p> : ""
                                    }
                                    <Dimmer active={props.loading}><Loader indeterminate>Querying Product Catalog</Loader></Dimmer>
                                    {
                                        props.productCatalogErrorText.length > 0 ?
                                            <Message error icon="exclamation" header="Error" content={props.productCatalogErrorText} /> :
                                            props.productCatalogWarningText.length > 0 ?
                                                <Message warning icon="warning" header="Warning" content={props.productCatalogWarningText} /> :
                                                <Table celled compact fixed>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell>Product</Table.HeaderCell>
                                                            <Table.HeaderCell>Entitlements</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {
                                                            Object.keys(productEntitlementMap).map(universalProductIndicator =>
                                                                productEntitlementMap[universalProductIndicator].map(
                                                                    (entitlement, index) =>
                                                                        index === 0 ?
                                                                            <Table.Row><Table.Cell>{universalProductIndicator}</Table.Cell><Table.Cell>{entitlement}</Table.Cell></Table.Row> :
                                                                            <Table.Row><Table.Cell/><Table.Cell>{entitlement}</Table.Cell></Table.Row>
                                                                )
                                                            )
                                                        }
                                                    </Table.Body>

                                                </Table>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Dimmer.Dimmable>
                </Accordion.Content>
            }
        />
    );
};
