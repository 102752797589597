import React, {Component} from "react";

import JSONEditor from "jsoneditor";
import SynapseDataProvider from "../../../Services/SynapseMetadataDataProvider";
import {Button, Form, Grid, Select, Icon, Message} from "semantic-ui-react";
import { Prompt } from 'react-router'
import BasicModal from "../../BasicModal/BasicModal";

SynapseDataProvider.init(process.env.SYNAPSE_CORE_URL);


export default class SynapseMetadataMasterDetailView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            metadata: {},
            currentEditorText: {},
            lastEditorText: {},
            selectedAttribute: "",
            attributeOptions: [],
            loading: false,
            saving: false,
            errorMessage: "",
            successMessage: "",
            changeEditor: false,
            showModal: false,
            prevState:{},
        };

    }

    handleWindowBeforeUnload = (event) => {
        if (this.state.changeEditor && this.state.successMessage === ""){
            event.preventDefault();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
    }

    componentDidMount() {
        const options = {
            mode: "text",
            onChange: () => {
                const text = this.jsoneditor.get();
                if (text !== this.state.currentEditorText) {
                    this.setState({
                        currentEditorText: text,
                        changeEditor: true
                    });
                }
            }
        };
        this.jsoneditor = new JSONEditor(this.container, options);
        this.jsoneditor.set(this.state.currentEditorText);

        this.refreshMetadata();

        if (this.state.metadata) {
            this.generateAttributeOptions();
        }

        window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
    }

    generateAttributeOptions = () => {
        this.setState({attributeOptions: Object.keys(this.state.metadata).map(option => {
                return {key: option, value: option, text: option};
            })
        }, () => {
            console.log("(SynapseMetadataMasterDetailView.generateAttributeOptions) Setting attribute options to ", this.state.attributeOptions, "from metadata", this.state.metadata);
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.metadata !== prevState.metadata) {
            this.generateAttributeOptions();
        }

        if (this.state.selectedAttribute !== prevState.selectedAttribute && this.state.changeEditor && this.state.successMessage === "" ) {
            this.setState({
                showModal: true,
                prevState: prevState.selectedAttribute
            });
        } else if  (this.state.selectedAttribute !== prevState.selectedAttribute) {
            const text = this.state.metadata.hasOwnProperty(this.state.selectedAttribute) ? this.state.metadata[this.state.selectedAttribute] : {};
            this.setState({currentEditorText: text, lastEditorText: text}, () => {
                this.jsoneditor.set(this.state.currentEditorText);
            });
        } 
        
    }

    handleUnSave = () => {
        this.state.selectedAttribute = this.state.prevState
        this.setState({
            showModal: false
        });
    }

    handleLeave = () =>{
        const text = this.state.metadata.hasOwnProperty(this.state.selectedAttribute) ? this.state.metadata[this.state.selectedAttribute] : {};
        this.setState({currentEditorText: text, lastEditorText: text}, () => {
            this.jsoneditor.set(this.state.currentEditorText);
        });

        //// ***NEED TO PUT IF STATEMENT IF Metadata matches old after edits then allow to leave without popup**

        // ** Other side note...save doesnt update right away maybe place a force refresh**

        this.setState({
            changeEditor: false,
            showModal: false
        });
    }

    refreshMetadata = () => {
        this.setState({loading: true, errorMessage: "", successMessage: ""}, () => {
            SynapseDataProvider.getMetadata().then(response => {
                console.log("SynapseMetadataMasterDetailView.refreshMetadata response: ", response);
                if (response.hasOwnProperty("error")) {
                    this.props.toast("Error", "There was an error retrieving Synapse metadata.", "error");
                    this.setState({metadata: [], errorMessage: "There was an error retrieving Synapse metadata."});
                }
                if (response.hasOwnProperty("metadata")) {
                    this.setState({metadata: response.metadata});
                }
            }).catch(error => {
                console.error(error);
                this.setState({metadata: [], errorMessage: "There was an error retrieving Synapse metadata."});
            }).finally(() => {
                this.setState({loading: false});
            });
        });

    };

    saveMetadata = () => {
        this.setState({lastEditorText: this.state.currentEditorText, saving: true, errorMessage: "", successMessage: ""}, () => {
            SynapseDataProvider.setMetadata(this.state.selectedAttribute, this.state.currentEditorText).then(response => {
                console.log(response);
                if (response.error) {
                    this.setState({errorMessage: "There has been an error saving the metadata."});
                } else {
                    this.setState({successMessage: "Metadata updated."});
                }
            }).catch(error => {
                this.setState({errorMessage: "There has been an error saving the metadata."});
                console.log(error);
            }).finally(() => {
                this.setState({saving: false});
            });
        });
    };

    resetValue = () => {

        this.setState({currentEditorText: this.state.lastEditorText}, () => {
            this.jsoneditor.set(this.state.currentEditorText);
        });
        this.setState({changeEditor: false});

    };

   

    render() {

        return (
            <Grid className="masterContainer">
                <Prompt
                    when={this.state.changeEditor && this.state.successMessage === ""}
                    message='You have unsaved changes, are you sure you want to leave?'
                /> 
           
                <BasicModal
                    onClose={this.handleUnSave}
                    onOpen={this.handleLeave}
                    open={this.state.showModal}
                    headerText={""}
                    modalContent={"You have unsaved changes, are you sure you want to leave?"}
                />
                <Grid.Column className="masterContainer" width={16}>
                    <Grid.Row style={{paddingBottom: "3ch"}}>
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Field
                                    label="Select Attribute"
                                    control={Select}
                                    fluid
                                    options={this.state.attributeOptions}
                                    value={this.state.selectedAttribute}
                                    onChange={(event, {value}) => this.setState({selectedAttribute: value})}
                                    onAddItem={(event, {value}) => this.setState({metadata: Object.assign({}, this.state.metadata, {[value]: {}})})}
                                    search
                                    allowAdditions
                                    width={14}
                                />
                                <Form.Field width={2} fluid>
                                    <label>&nbsp;</label>
                                    <Button fluid icon onClick={() => this.refreshMetadata()}><Icon name="refresh"/>&nbsp;&nbsp;&nbsp;Refresh</Button>
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Row>
                    {
                        this.state.loading ?
                            <Message color="yellow" icon>
                                <Icon name="spinner" loading />
                                <Message.Content>
                                    Loading metadata...
                                </Message.Content>
                            </Message> :
                            this.state.saving ?
                                <Message color="blue" icon>
                                    <Icon name="spinner" loading />
                                    <Message.Content>
                                        Saving...
                                    </Message.Content>
                                </Message> :
                                this.state.errorMessage ?
                                    <Message color="red" icon>
                                        <Icon name="exclamation" />
                                        <Message.Content>{this.state.errorMessage}</Message.Content>
                                    </Message> :
                                    this.state.successMessage ?
                                        <Message color="green" icon>
                                            <Icon name="plus" />
                                            <Message.Content>{this.state.successMessage}</Message.Content>
                                        </Message> : ""
                    }
                    <Grid.Row>
                        <Form>
                            <Form.Field>
                                <label>Attribute Data</label>
                            </Form.Field>
                        </Form>
                        <div style={{height: "57vh"}} ref={elem => this.container = elem}/>
                    </Grid.Row>
                    <Grid.Row style={{paddingTop: "3ch"}}>
                        <Button onClick={this.saveMetadata} primary disabled={!this.state.selectedAttribute}>Save Metadata</Button>
                        <Button onClick={this.resetValue} disabled={!this.state.selectedAttribute}>Reset Value</Button>
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        );
    }
};
