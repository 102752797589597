import React, {useState, useEffect} from "react";
import {Accordion, Container, Dimmer, Grid, Icon, Loader, Message, Table} from "semantic-ui-react";

export default function AdaptiAccordionPanel(props) {
    const [entitlements, setEntitlements] = useState([]);

    useEffect(function updateEntitlements() {
        const updatedEntitlements = new Set();
        if (props.adaptiData.hasOwnProperty("restrictions")) {
            for (const restriction of props.adaptiData.restrictions) {
                if (restriction.hasOwnProperty("entitlements")) {
                    for (const entitlement of restriction.entitlements) {
                        if (entitlement.hasOwnProperty("entitlement")) {
                            updatedEntitlements.add(entitlement.entitlement);
                        }
                    }
                }
            }
        }

        setEntitlements(Array.from(updatedEntitlements));
    }, [props.adaptiData]);

    return (
        <Accordion.Panel
            onTitleClick={props.onTitleClick}
            active={props.active}
            title="Adapti"
            as={Container}
            content={
                <Accordion.Content>
                    <Dimmer.Dimmable dimmed={props.loading}>
                        <Grid>
                            <Grid.Column textAlign="left">
                                {
                                    props.loading ? <p><br /><br /><br /><br /></p> : ""
                                }
                                <Dimmer active={props.loading}><Loader indeterminate>Querying Adapti</Loader></Dimmer>
                                {
                                    props.adaptiErrorText.length > 0 ?
                                        <Message error icon="exclamation" header="Error" content={props.adaptiErrorText}/> :
                                        props.adaptiWarningText.length > 0 ?
                                            <Message warning icon="warning" header="Warning" content={props.adaptiWarningText} /> :
                                            <Table celled compact fixed>
                                                <Table.Row>
                                                    <Table.HeaderCell>Org</Table.HeaderCell>
                                                    <Table.Cell>{props.org}</Table.Cell>
                                                </Table.Row>
                                                {
                                                    entitlements.map((entitlement, index) =>
                                                        index === 0 ?
                                                            <Table.Row><Table.HeaderCell>Entitlements</Table.HeaderCell><Table.Cell>{entitlement}</Table.Cell></Table.Row> :
                                                            <Table.Row><Table.HeaderCell /><Table.Cell>{entitlement}</Table.Cell></Table.Row>
                                                    )
                                                }
                                            </Table>
                                }
                            </Grid.Column>
                        </Grid>
                    </Dimmer.Dimmable>
                </Accordion.Content>
            }
        />
    );
};
