import React, {useEffect} from "react";
import {Container, Header, Icon, Message, Table} from "semantic-ui-react";
import ReactTable from "react-table-v6";

export default function AssetInfo(props) {
    useEffect(function notifyOfError() {
        if (!Array.isArray(props.flights) || props.flights.length < 1 ||
        !Array.isArray(props.assetIds) || props.assetIds.length < 1) {
            props.setError(true);
        }
        console.log(props.flights);
    }, [props.flights, props.assetIds]);

    return (
        <Container fluid>
            {
                !Array.isArray(props.assetIds) || props.assetIds.length < 1 ?
                    <Message icon="exclamation" negative header="Error" content={`${props.id} has no asset IDs.`} /> : ""
            }
            {
                props.id || props.brand || props.assetIds || props.lastUpdate ?
                <Table compact celled>
                    <Table.Body>
                        {
                            props.id ? <Table.Row><Table.Cell><b>ID</b></Table.Cell><Table.Cell>{props.id}</Table.Cell></Table.Row> : ""
                        }
                        {
                            props.brand ? <Table.Row><Table.Cell><b>Brand</b></Table.Cell><Table.Cell>{props.brand}</Table.Cell></Table.Row> : ""
                        }
                        {
                            Array.isArray(props.assetIds) ? props.assetIds.map((assetId, index) => {
                                return index === 0 ?
                                    <Table.Row><Table.Cell><b>Asset IDs</b></Table.Cell><Table.Cell>{assetId}</Table.Cell></Table.Row> :
                                    <Table.Row><Table.Cell /><Table.Cell>{assetId}</Table.Cell></Table.Row>
                            }) : ""
                        }
                        {
                            props.lastUpdate ? <Table.Row><Table.Cell><b>Last Update</b></Table.Cell><Table.Cell>{props.dateTimeFormat.format(new Date(props.lastUpdate))}</Table.Cell></Table.Row> : ""
                        }
                    </Table.Body>
                </Table> : ""
            }
            {
                !Array.isArray(props.flights) || props.flights.length < 1 ?
                    <Message icon="exclamation" color="red" header="Error" content={`${props.id} has no flight windows.`} /> :
                    <Container fluid>
                        <Header as="h5" textAlign="center">Flight Windows</Header>
                        <ReactTable
                            data={props.flights}
                            columns={[
                                {Header: "Start Time", accessor: "start", Cell: row => <span>{props.dateTimeFormat.format(new Date(parseInt(row.value)))}</span>},
                                {Header: "End Time", accessor: "end", Cell: row => <span>{props.dateTimeFormat.format(new Date(parseInt(row.value)))}</span>},
                                {Header: "AuthN", accessor: "authn", Cell: row => <Container textAlign="center"><Icon name={row.value ? "checkmark" : "x"} /></Container>, width: 56},
                                {Header: "Products", accessor: "products", Cell: row => row.value ? <span>{row.value.join(", ")}</span> : ""},
                                {Header: "Entitlements", accessor: "entitlements", Cell: row => row.value ? <span>{row.value.join(", ")}</span> : ""}
                            ]}
                            className="-striped -highlight"
                            defaultPageSize={5}
                        />
                    </Container>
            }
        </Container>
    );
};
