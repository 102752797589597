import React, {useState, useEffect} from "react";
import {Accordion, Container, Dimmer, Grid, Icon, Loader, Message} from "semantic-ui-react";
import AssetInfo from "./AssetInfo";

export default function ConsensoAccordionPanel(props) {
    const [consensoAssets, setConsensoAssets] = useState([]);

    useEffect(function getConsensoAssets() {
        if (props.consensoResponse) {
            console.log("(ConsensoAccordionPanel.getConsensoAssets) consenso response: ", props.consensoResponse);
            setConsensoAssets(props.consensoResponse);
        } else {
            setConsensoAssets([]);
            props.setConsensoError(true);
        }
    }, [props.consensoResponse]);

    return (
        <Accordion.Panel
            onTitleClick={props.onTitleClick}
            active={props.active}
            title="Consenso"
            as={Container}
            content={
                <Accordion.Content>
                    <Dimmer.Dimmable dimmed={props.loading}>
                        {
                            props.loading ? <p><br /><br /><br /><br /></p> : ""
                        }
                        <Dimmer active={props.loading}><Loader indeterminate>Querying Consenso</Loader></Dimmer>
                        <Grid>
                            <Grid.Row textAlign="left">
                                {
                                    consensoAssets.length < 1 ?
                                        <Grid.Column width={16} textAlign="left">
                                            <Message color="green" icon="info" header="Results" content={`No assets were found in Consenso with the mediaId ${props.mediaId}.`} />
                                        </Grid.Column> :
                                    props.consensoError ?
                                        <Grid.Column width={16} textAlign="left">
                                            <Message error icon="exclamation" header="Error" content={props.consensoError} />
                                        </Grid.Column> : ""
                                }
                                <Grid.Column width={16} textAlign="left">
                                    {
                                        consensoAssets.map((asset, index) => {
                                            return <AssetInfo
                                                key={`consenso-asset-${index}`}
                                                id={asset._id}
                                                flights={asset.flights}
                                                assetIds={asset.assetIds}
                                                setError={props.setConsensoError}
                                                brand={asset.brand}
                                                lastUpdate={asset.updateTime}
                                                dateTimeFormat={props.dateTimeFormat}
                                            />
                                        })
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Dimmer.Dimmable>
                </Accordion.Content>
            }
        />
    );
};
