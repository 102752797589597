import React, {useState, useEffect} from "react";
import {Container, Grid, Icon, Message, Table} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import OriginCDNMapperDataProvider from "../../../Services/OriginCDNMapperDataProvider";

export default function OriginCDNInfo(props) {
    const [cdns, setCdns] = useState([]);
    const [errorText, setErrorText] = useState("");

    useEffect(function updateCDNs() {
        console.log("(OriginCDNInfo.updateCDNs) brand? ", props.brand, "origin? ", props.origin, "user? ", props.user);
        if (props.brand && props.origin && props.user) {
            props.setCompleted(false);
            OriginCDNMapperDataProvider.getByBrandAndOrigin(props.brand, props.origin, props.user).then(cdns => {
                if (cdns.length > 0) {
                    setCdns(cdns[0].cdns);
                    setErrorText("");
                } else {
                    props.setOriginCDNMapperError(true);
                    setErrorText(`No CDNs were found for the origin ${props.origin.originUrl}.`);
                }
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                props.setCompleted(true);
            });
        }
    }, [props.origins, props.brand]);

    return (
        <Container fluid>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16} textAlign="left">
                        <Message hidden={errorText.length === 0} icon error>
                            <Icon name="exclamation"/>
                            <Message.Content>
                                <Message.Header>Error</Message.Header>
                                {errorText}
                            </Message.Content>
                        </Message>
                        <Table>
                            <Table.Body>
                                <Table.Row><Table.Cell><b>Brand</b></Table.Cell><Table.Cell>{props.brand}</Table.Cell></Table.Row>
                                <Table.Row><Table.Cell><b>Origin URL</b></Table.Cell><Table.Cell>{props.origin.originUrl}</Table.Cell></Table.Row>
                                <Table.Row><Table.Cell><b>Origin Type</b></Table.Cell><Table.Cell>{props.origin.originType}</Table.Cell></Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <ReactTable
                            columns={[
                                {Header: "CDN Type", accessor: "cdnType"},
                                {Header: "CDN Priority", accessor: "cdnPriority"},
                                {
                                    Header: "SSAI",
                                    accessor: "cdnIsSSAI",
                                    Cell: row => <Container textAlign="center"><Icon name={row.value? "checkmark" : "x"} /></Container>
                                },
                                {Header: "CDN Protection Type", accessor: "cdnProtectionType"},
                                {Header: "Content Type", accessor: "cdnContentType"},
                                {
                                    Header: "Enabled",
                                    accessor: "cdnIsEnabled",
                                    Cell: row => <Container textAlign="center"><Icon name={row.value? "checkmark" : "x"} /></Container>
                                }
                            ]}
                            data={cdns}
                            loading={props.loading}
                            className="-striped -highlight"
                            defaultPageSize={5}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};
