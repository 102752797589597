import React, {useEffect, useState} from "react";
import {Accordion, Container, Dimmer, Loader} from "semantic-ui-react";
import OriginCDNInfo from "./OriginCDNInfo";

export default function OriginCDNMapperAccordionPanel(props) {
    const [cdnsLoadingCompleted, setCdnsLoadingCompleted] = useState([]);

    useEffect(function onOriginsUpdate() {
        console.log("(OriginCDNMapperAccordionPanel.onOriginsUpdate: origins: ", props.origins);
        const updatedCdnsLoadingCompleted = [];
        for (const origin of props.origins) {
            updatedCdnsLoadingCompleted.push(false);
        }

        setCdnsLoadingCompleted(updatedCdnsLoadingCompleted);
    }, [JSON.stringify(props.origins)]);

    useEffect(function onCdnsLoadingUpdate() {
        let updatedCompleted = true;
        console.log("OriginCDNMapperAccordionPanel.onCdnsLoadingUpdate cdnsLoadingCompleted? ", cdnsLoadingCompleted);
        for (const cdnLoadingCompleted of cdnsLoadingCompleted) {
            updatedCompleted = updatedCompleted && !!cdnLoadingCompleted;
        }

        console.log("OriginCDNMapperAccordionPanel.onCdnsLoadingUpdate setting props.completed to ", updatedCompleted);
        props.setCompleted(updatedCompleted);
        props.setLoading(!updatedCompleted);
    }, [JSON.stringify(cdnsLoadingCompleted)]);

    return (
        <Accordion.Panel
            onTitleClick={props.onTitleClick}
            active={props.active}
            title="Origin-CDN Mapper"
            as={Container}
            content={
                <Accordion.Content style={{hidden: !props.active.toString()}}>
                    <Dimmer.Dimmable dimmed={props.loading}>
                        {
                            props.loading ? <p><br /><br /></p> : ""
                        }
                        <Dimmer active={props.loading}><Loader indeterminate>Querying Origin-CDN Mapper</Loader></Dimmer>
                        {props.origins.map((origin, index) => {
                            let brand = origin.brand;

                            if (!brand) {
                                brand = props.brand;
                            }

                            return (
                                <OriginCDNInfo
                                    key={`origin-group-${index}`}
                                    brand={brand}
                                    origin={origin.origin}
                                    user={props.user}
                                    setCompleted={
                                        completed => {
                                            console.log(`OriginCDNMapperAccordionPanel.setCompleted setting completed for index ${index} to ${completed}`);
                                            const updatedCDNsLoadingCompleted = [];
                                            for (let i = 0; i < cdnsLoadingCompleted.length; i++) {
                                                updatedCDNsLoadingCompleted[i] = index === i ? completed : updatedCDNsLoadingCompleted[i];
                                            }

                                            setCdnsLoadingCompleted(updatedCDNsLoadingCompleted);
                                        }
                                    }
                                    loading={props.loading}
                                    setOriginCDNMapperError={props.setOriginCDNMapperError}
                                />);
                        })
                        }
                    </Dimmer.Dimmable>

                </Accordion.Content>
            }
        />
    );
};
