import React from "react";
import {Icon, Step} from "semantic-ui-react";

export default function SundialInvestigationStepGroup(props) {
    return (
        <Step.Group size="small" fluid>
            <Step disabled={props.mediumAuditDisabled}>
                {
                    props.mediumAuditDisabled ? <Icon name="medium m" disabled /> :
                        props.mediumAuditError ? <Icon name="medium m" color="red"/> :
                            props.mediumAuditWarning ? <Icon name="medium m" color="yellow" /> :
                                props.mediumAuditCompleted ? <Icon name="medium m" color="green" /> :
                                    <Icon loading name="spinner" />
                }
                <Step.Content>
                    <Step.Title>Medium Audit</Step.Title>
                </Step.Content>
            </Step>
            <Step disabled={props.originCDNMapperDisabled}>
                {
                    props.originCDNMapperDisabled ? <Icon name="map signs" disabled /> :
                        props.originCDNMapperError ? <Icon name="map signs" color="red" /> :
                            props.originCDNMapperWarning ? <Icon name="map signs" color="yellow"/> :
                                props.originCDNMapperCompleted ? <Icon name="map signs" color="green" /> :
                                    <Icon loading name="spinner" />
                }
                <Step.Content>
                    <Step.Title>Origin-CDN Mapper</Step.Title>
                </Step.Content>
            </Step>
            <Step disabled={props.mediumDisabled}>
                {
                    props.mediumDisabled ? <Icon name="medium" disabled /> :
                        props.mediumError ? <Icon name="medium" color="red" /> :
                            props.mediumWarning ? <Icon name="medium" color="yellow" /> :
                                props.mediumCompleted ? <Icon name="medium" color="green" /> :
                                    <Icon loading name="spinner" />
                }
                <Step.Content>
                    <Step.Title>Medium</Step.Title>
                </Step.Content>
            </Step>
            <Step disabled={props.consensoDisabled}>
                {
                    props.consensoDisabled ? <Icon name="handshake" disabled /> :
                        props.consensoError ? <Icon name="handshake" color="red" /> :
                            props.consensoWarning ? <Icon name="handshake" color="yellow" /> :
                                props.consensoCompleted ? <Icon name="handshake" color="green" /> :
                                    <Icon loading name="spinner" />
                }
                <Step.Content>
                    <Step.Title>Consenso</Step.Title>
                </Step.Content>
            </Step>
            <Step disabled={props.tokenDisabled}>
                {
                    props.tokenDisabled ? <Icon name="ticket" disabled /> :
                        props.tokenError ? <Icon name="ticket" color="red" /> :
                            props.tokenWarning ? <Icon name="ticket" color="yellow" /> :
                                props.tokenCompleted ? <Icon name="ticket" color="green" /> :
                                    <Icon loading name="spinner" />
                }
                <Step.Content>
                    <Step.Title>Token</Step.Title>
                </Step.Content>
            </Step>
            <Step disabled={props.adaptiDisabled}>
                {
                    props.adaptiDisabled ? <Icon name="autoprefixer" disabled /> :
                        props.adaptiError ? <Icon name="autoprefixer" color="red" /> :
                            props.adaptiWarning ? <Icon name="autoprefixer" color="yellow" /> :
                                props.adaptiCompleted ? <Icon name="autoprefixer" color="green" /> :
                                    <Icon loading name="spinner" />
                }
                <Step.Content>
                    <Step.Title>Adapti</Step.Title>
                </Step.Content>
            </Step>
            <Step disabled={props.productCatalogDisabled}>
                {
                    props.productCatalogDisabled ? <Icon name="boxes" disabled /> :
                        props.productCatalogError ? <Icon name="boxes" color="red" /> :
                            props.productCatalogWarning ? <Icon name="boxes" color="yellow" /> :
                                props.productCatalogCompleted ? <Icon name="boxes" color="green" /> :
                                    <Icon loading name="spinner" />
                }
                <Step.Content>
                    <Step.Title>Product Catalog</Step.Title>
                </Step.Content>
            </Step>
        </Step.Group>
    )
};
