import React, {useEffect, useState} from "react";
import {Message, Accordion, Container, Dimmer, Loader, Table, Grid} from "semantic-ui-react";

export default function MediumAuditAccordionPanel(props) {
    const [lastUpdate, setLastUpdate] = useState("");
    const [prerollCaid, setPrerollCaid] = useState("");

    useEffect(function formatLastUpdateString() {
        if (props.lastUpdate) {
            const lastUpdateAsDate = new Date(props.lastUpdate);
            setLastUpdate(props.dateTimeFormat.format(lastUpdateAsDate));
        } else {
            setLastUpdate("");
        }
    }, [props.lastUpdate]);

    useEffect(function setPrerollCaidFromResponse() {
        if (props.mediumAuditResponse && props.mediumAuditResponse.hasOwnProperty("ads")) {
            setPrerollCaid(props.mediumAuditResponse.ads.prerollCaid);
        } else {
            setPrerollCaid("");
        }
    }, [JSON.stringify(props.mediumAuditResponse)]);

    return (
        <Accordion.Panel
            onTitleClick={props.onTitleClick}
            active={props.active}
            title="Medium Audit"
            as={Container}
            content={
                <Accordion.Content>
                    <Dimmer.Dimmable dimmed={props.loading}>
                        <Grid>
                            <Grid.Column textAlign="left">
                                {
                                    props.loading ? <p><br /><br /><br /><br /></p> : ""
                                }
                                <Dimmer active={props.loading}><Loader indeterminate>Querying Medium Audit</Loader></Dimmer>
                                {
                                    props.error ? <Message icon="exclamation" header="Error" content={props.error} negative /> : ""
                                }
                                {
                                    !props.foreignId && (lastUpdate || prerollCaid) && !props.error && props.mediumAuditCompleted ? <Message icon="warning" header="Warning" color="yellow" content="This asset has no associated foreign ID." /> : ""
                                }
                                {
                                    !props.foreignId && !lastUpdate && !prerollCaid && !props.error && props.mediumAuditCompleted ? <Message icon="check" color="green" header="Results" content={`The mediaId ${props.mediaId} was not found in Medium Audit.`}/> : ""
                                }
                                <Container textAlign="left" fluid>
                                    <Table celled compact>
                                        <Table.Body>
                                            {
                                                props.foreignId ? <Table.Row><Table.Cell><b>Foreign ID</b></Table.Cell><Table.Cell>{props.foreignId}</Table.Cell></Table.Row> : ""
                                            }
                                            {
                                                prerollCaid ? <Table.Row><Table.Cell><b>Preroll CAID</b></Table.Cell><Table.Cell>{prerollCaid}</Table.Cell></Table.Row> : ""
                                            }
                                            {
                                                lastUpdate ? <Table.Row><Table.Cell><b>Last Update</b></Table.Cell><Table.Cell>{lastUpdate}</Table.Cell></Table.Row> : ""
                                            }
                                        </Table.Body>
                                    </Table>
                                </Container>
                            </Grid.Column>
                        </Grid>
                    </Dimmer.Dimmable>
                </Accordion.Content>
            }
        />
    );
};
