import React, {useState, useEffect} from "react";
import {Grid, Form, Accordion, Container, Divider} from "semantic-ui-react";
import OriginCDNMapperDataProvider from "../../../Services/OriginCDNMapperDataProvider";
import MediumAuditDataProvider from "../../../Services/MediumAuditDataProvider";
import MediumDataProvider from "../../../Services/MediumDataProvider";
import TokenAdminDataProvider from "../../../Services/TokenAdminDataProvider";
import ConsensoDataProvider from "../../../Services/ConsensoDataProvider";
import AdaptiDataProvider from "../../../Services/AdaptiDataProvider";
import ProductCatalogDataProvider from "../../../Services/ProductCatalogDataProvider";
import OriginCDNMapperAccordionPanel from "./OriginCDNMapperAccordionPanel";
import SundialInvestigationStepGroup from "./SundialInvestigationStepGroup";
import MediumAccordionPanel from "./MediumAccordionPanel";
import TokenAccordionPanel from "./TokenAccordionPanel";
import MediumAuditAccordionPanel from "./MediumAuditAccordionPanel";
import ConsensoAccordionPanel from "./ConsensoAccordionPanel";
import AdaptiAccordionPanel from "./AdaptiAccordionPanel";
import ProductCatalogAccordionPanel from "./ProductCatalogAccordionPanel";

const DATE_TIME_FORMAT = Intl.DateTimeFormat("en-US", {
    year: "numeric", month: "long", day: "2-digit",
    hour: "numeric", minute: "numeric", second: "numeric",
    timeZone: "America/New_York", timeZoneName: "short"
});

MediumDataProvider.init({baseURL: process.env.MEDIUM_URL});
OriginCDNMapperDataProvider.init({baseURL: process.env.ORIGIN_CDN_MAPPER_URL});
MediumAuditDataProvider.init({baseURL: process.env.MEDIUM_URL});
TokenAdminDataProvider.init({baseURL: process.env.TOKEN_ADM_URL});
AdaptiDataProvider.init(process.env.ADAPTI_URL);
ProductCatalogDataProvider.init(process.env.PRODUCT_CATALOG_URL);

export default function SundialMasterDetailView(props) {
    const [mediaId, setMediaId] = useState("");
    const [frozenMediaId, setFrozenMediaId] = useState("");
    const [brand, setBrand] = useState("");
    const [normalizedBrand, setNormalizedBrand] = useState("");
    const [org, setOrg] = useState("");
    const [showAccordion, setShowAccordion] = useState(false);
    const [investigateClicked, setInvestigateClicked] = useState(false);
    const [resetComplete, setResetComplete] = useState(false);

    const [mediumAuditDisabled, setMediumAuditDisabled] = useState(true);
    const [originCDNMapperDisabled, setOriginCDNMapperDisabled] = useState(true);
    const [mediumDisabled, setMediumDisabled] = useState(true);
    const [tokenDisabled, setTokenDisabled] = useState(true);
    const [consensoDisabled, setConsensoDisabled] = useState(true);
    const [adaptiDisabled, setAdaptiDisabled] = useState(true);
    const [productCatalogDisabled, setProductCatalogDisabled] = useState(true);

    const [mediumAuditCompleted, setMediumAuditCompleted] = useState(false);
    const [originCDNMapperCompleted, setOriginCDNMapperCompleted] = useState(false);
    const [mediumCompleted, setMediumCompleted] = useState(false);
    const [tokenCompleted, setTokenCompleted] = useState(false);
    const [consensoCompleted, setConsensoCompleted] = useState(false);
    const [adaptiCompleted, setAdaptiCompleted] = useState(false);
    const [productCatalogCompleted, setProductCatalogCompleted] = useState(false);

    const [mediumAuditError, setMediumAuditError] = useState(false);
    const [originCDNMapperError, setOriginCDNMapperError] = useState(false);
    const [mediumError, setMediumError] = useState(false);
    const [tokenError, setTokenError] = useState(false);
    const [consensoError, setConsensoError] = useState(false);
    const [adaptiError, setAdaptiError] = useState(false);
    const [productCatalogError, setProductCatalogError] = useState(false);

    const [mediumAuditWarning, setMediumAuditWarning] = useState(false);
    const [originCDNMapperWarning, setOriginCDNMapperWarning] = useState(false);
    const [mediumWarning, setMediumWarning] = useState(false);
    const [tokenWarning, setTokenWarning] = useState(false);
    const [consensoWarning, setConsensoWarning] = useState(false);
    const [adaptiWarning, setAdaptiWarning] = useState(false);
    const [productCatalogWarning, setProductCatalogWarning] = useState(false);

    const [mediumAuditPanelActive, setMediumAuditPanelActive] = useState(false);
    const [originCDNMapperPanelActive, setOriginCDNMapperPanelActive] = useState(false);
    const [mediumPanelActive, setMediumPanelActive] = useState(false);
    const [tokenPanelActive, setTokenPanelActive] = useState(false);
    const [consensoPanelActive, setConsensoPanelActive] = useState(false);
    const [adaptiPanelActive, setAdaptiPanelActive] = useState(false);
    const [productCatalogPanelActive, setProductCatalogPanelActive] = useState(false);

    const [mediumAuditLoading, setMediumAuditLoading] = useState(false);
    const [originCDNMapperPanelLoading, setOriginCDNMapperPanelLoading] = useState(false);
    const [mediumLoading, setMediumLoading] = useState(false);
    const [tokenLoading, setTokenLoading] = useState(false);
    const [consensoLoading, setConsensoLoading] = useState(false);
    const [adaptiLoading, setAdaptiLoading] = useState(false);
    const [productCatalogLoading, setProductCatalogLoading] = useState(false);

    const [origins, setOrigins] = useState([]);
    const [mediumAuditResponse, setMediumAuditResponse] = useState({});
    const [mediumAuditErrorText, setMediumAuditErrorText] = useState(null);
    const [adaptiWarningText, setAdaptiWarningText] = useState("");
    const [adaptiErrorText, setAdaptiErrorText] = useState("");
    const [productCatalogWarningText, setProductCatalogWarningText] = useState("");
    const [productCatalogErrorText, setProductCatalogErrorText] = useState("");
    const [foreignId, setForeignId] = useState("");
    const [mediumAuditLastUpdate, setMediumAuditLastUpdate] = useState(null);

    const [mediumResponse, setMediumResponse] = useState({});
    const [tokenAdminResponse, setTokenAdminResponse] = useState([]);
    const [consensoResponse, setConsensoResponse] = useState([]);
    const [adaptiData, setAdaptiData] = useState({});
    const [productCatalogResponse, setProductCatalogResponse] = useState([]);

    useEffect(function triggerSearchOnLoadIfMediaIdInPath() {
        const pathParam = props.match.params.mediaId;
        console.log("(SundialMasterDetailView.triggerSearchOnLoadIfMediaIdInPath) path param was passed?", pathParam !== undefined);
        if (pathParam) {
            setMediaId(pathParam);
            setInvestigateClicked(true);
        }
    }, []);

    useEffect(function getBrandFromConsensoResponse() {
        console.log("(SundialMasterDetailView.getBrandFromConsensoResponse) consenso response: ", consensoResponse);
        if (consensoResponse.length > 0 && consensoResponse[0].hasOwnProperty("brand")) {
            const updatedBrand = consensoResponse[0].brand;
            console.log("(SundialMasterDetailView.getBrandFromConsensoResponse) setting brand to: ", updatedBrand);
            setBrand(updatedBrand);
        }
    }, [JSON.stringify(consensoResponse)]);

    useEffect(function normalizeBrand() {
        if (brand.length > 0) {
            const updatedNormalizedBrand = brand.toLowerCase().replaceAll(/[^a-z0-9]/g, "");
            console.log("(SundialMasterDetailView.normalizeBrand) updating normalized brand to ", updatedNormalizedBrand);
            setNormalizedBrand(updatedNormalizedBrand);
        }
    }, [brand]);

    useEffect(function investigateButtonClicked() {
        setShowAccordion(true);
        if (investigateClicked) {
            reset();
            setFrozenMediaId(mediaId);
            setInvestigateClicked(false);
        }
    }, [investigateClicked]);

    const executeMediumAuditCheck = () => {
        console.log("(SundialMasterDetailView.executeMediumAuditCheck) reset complete, running medium audit check");
        setResetComplete(false);
        setMediumAuditPanelActive(true);
        queryMediumAudit().then(brandOriginList => {
            if (brandOriginList.length < 1 || mediumAuditError) {
                queryMediumOrigins().then(mediumOrigins => {
                    setOrigins(mediumOrigins);
                });
            } else {
                setOrigins(brandOriginList);
            }
        }).catch(error => {
            console.error("(SundialMasterDetailView.executeMediumAuditCheck) error", error);
            setMediumAuditError(true);
            setInvestigateClicked(false);
        }).finally(() => {
            console.log("(SundialMasterDetailView.executeMediumAuditCheck) origins: ", origins);
            setMediumAuditCompleted(true);
        });
    };

    const executeMediumV2Check = () => {
        console.log(`(SundialMasterDetailView.executeMediumV2Check) initiate medium request for media ID ${mediaId}`);
        setMediumPanelActive(true);
        setMediumDisabled(false);
        setMediumLoading(true);
        MediumDataProvider.getMediaJSON(mediaId).then(mediaJSON => {
            console.log("(SundialMasterDetailView.runMediumV2Check) mediaJson: ", mediaJSON);

            for (const origin of origins) {
                if (!origin.brand) {
                    origin.brand = mediaJSON.network;
                }
            }

            setMediumResponse(mediaJSON);
            setMediumError(mediaJSON && mediaJSON.error && true);
        }).catch(error => {
            setMediumError(true);
            setMediumResponse(error);
        }).finally(() => {
            setMediumCompleted(true);
            setMediumLoading(false);
        });
    };

    const executeTokenCheck = () => {
        setTokenPanelActive(true);
        setTokenLoading(true);
        console.log(`(SundialMasterDetailView.runTokenCheck) initiate token admin request for media ID ${mediaId}`);
        queryTokenAssets().then(async tokenAssets => {
            console.log("(SundialMasterDetailView.runTokenCheck) token assets: ", tokenAssets);
        }).catch(error => {
            console.error("(SundialMasterDetailView.runTokenCheck) error querying token assets?: ", error);
            setTokenError(true);
        }).finally(() => {
            console.log(`(SundialMasterDetailView.runTokenCheck) token is completed and no longer loading.`);
            setTokenCompleted(true);
            setTokenLoading(false);
        });
    };

    const executeConsensoCheck = () => {
        setConsensoPanelActive(true);
        setConsensoLoading(true);
        console.log(`(SundialMasterDetailView.runConsensoCheck) initiate consenso asset check for media ID ${mediaId}`);
        queryConsensoAssets().then(assets => {
            console.log("(SundialMasterDetailView.runConsensoCheck) consenso assets: ", assets);
        }).catch(error => {
            console.error("(SundialMasterDetailView.runConsensoCheck) error querying consenso assets: ", error);
            setConsensoError(true);
        }).finally(() => {
            console.log(`(SundialMasterDetailView.runConsensoCheck) consenso is completed and no longer loading.`);
            setConsensoCompleted(true);
            setConsensoLoading(false);
        });
    };

    useEffect(function executeChecks() {
        if (resetComplete) {
            console.log("(SundialMasterDetailView.executeChecks) reset is complete, so checks are being executed");
            executeMediumAuditCheck();
            executeMediumV2Check();
            executeTokenCheck();
            executeConsensoCheck();
        }
    }, [resetComplete]);

    useEffect(function runOriginCDNMapperCheck() {
        console.log("(SundialMasterDetailView.runOriginCDNMapperCheck) mediumAuditCompleted ? ", mediumAuditCompleted, "origins exist? ", origins.length > 0, "no medium audit error? ", !mediumAuditError);
        if (mediumAuditCompleted && !mediumAuditError && origins.length > 0 && brand.length > 0) {
            setOriginCDNMapperError(false);
            setOriginCDNMapperCompleted(true);
            setOriginCDNMapperPanelLoading(true);
            setOriginCDNMapperPanelActive(true);
            setOriginCDNMapperDisabled(false);
        } else {
            console.log("(SundialMasterDetailView.runOriginCDNMapperCheck) disabling origin-cdn mapper check");
            disableOriginCDNMapperCheck();
        }
    }, [mediumAuditCompleted, JSON.stringify(origins), brand]);

    useEffect(function runAdaptiCheck() {
        let brandToQuery = normalizedBrand;

        if (!brandToQuery) {
            if (Array.isArray(consensoResponse) && consensoResponse.length > 0 && consensoResponse[0].hasOwnProperty("brand")) {
                brandToQuery = consensoResponse[0].brand;
            }
        }

        if (consensoCompleted && brandToQuery) {
            setAdaptiPanelActive(true);
            setAdaptiLoading(true);
            console.log(`(SundialMasterDetailView.runAdaptiCheck) initiate adapti asset check for media ID ${mediaId} in brand ${brand}`);
            queryAdapti(brandToQuery).catch(error => {
                console.error("(SundialMasterDetailView.runAdaptiCheck) error querying adapti: ", error);
                setAdaptiError(true);
            }).finally(() => {
                setAdaptiLoading(false);
                setAdaptiCompleted(true);
            });
        } else if (normalizedBrand.length < 1) {
            console.log("(SundialMasterDetailView.runAdaptiCheck): the normalized brand was empty.");
            setAdaptiDisabled(true);
        } else {
            console.log("(SundialMasterDetailView.runAdaptiCheck): consenso did not complete.");
            setAdaptiDisabled(true);
        }
    }, [brand, consensoCompleted]);

    useEffect(function runProductCatalogCheck() {
        if (tokenCompleted && !tokenError && normalizedBrand.length > 0) {
            setProductCatalogDisabled(false);
            setProductCatalogPanelActive(true);
            setProductCatalogLoading(true);
            console.log("(SundialMasterDetailView.runProductCatalogCheck): querying product catalog.");
            queryProductCatalog().then(response => {
                console.log("(SundialMasterDetailView.runProductCatalogCheck): response from product catalog: ", response);
                setProductCatalogResponse(response);
            }).catch(error => {
                console.error("(SundialMasterDetailView.runProductCatalogCheck): error: ", error);
            }).finally(() => {
                setProductCatalogLoading(false);
                setProductCatalogCompleted(true);
            });
        } else if (tokenError) {
            console.log("(SundialMasterDetailView.runProductCatalogCheck): token had an error.");
            disableProductCatalogCheck();
        } else {
            console.log("(SundialMasterDetailView.runProductCatalogCheck): token did not complete.");
            disableProductCatalogCheck();
        }
    }, [tokenCompleted, tokenError, normalizedBrand]);

    const reset = () => {
        console.log("(SundialMasterDetailView.reset) reset initiated");
        setFrozenMediaId("");

        setMediumAuditDisabled(true);
        setOriginCDNMapperDisabled(true);
        setMediumDisabled(true);
        setTokenDisabled(true);
        setConsensoDisabled(true);
        setAdaptiDisabled(true);
        setProductCatalogDisabled(true);

        setMediumAuditWarning(false);
        setOriginCDNMapperWarning(false);
        setMediumWarning(false);
        setTokenWarning(false);
        setConsensoWarning(false);
        setAdaptiWarning(false);
        setProductCatalogWarning(false);

        setMediumAuditError(false);
        setOriginCDNMapperError(false);
        setMediumError(false);
        setTokenError(false);
        setConsensoError(false);
        setAdaptiError(false);
        setProductCatalogError(false);

        setMediumAuditCompleted(false);
        setOriginCDNMapperCompleted(false);
        setMediumCompleted(false);
        setTokenCompleted(false);
        setConsensoCompleted(false);
        setAdaptiCompleted(false);
        setProductCatalogCompleted(false);

        setMediumAuditPanelActive(false);
        setOriginCDNMapperPanelActive(false);
        setMediumPanelActive(false);
        setTokenPanelActive(false);
        setConsensoPanelActive(false);
        setAdaptiPanelActive(false);
        setProductCatalogPanelActive(false);

        setMediumAuditResponse({});
        setMediumAuditErrorText("");
        setMediumAuditLastUpdate("");
        setAdaptiErrorText("");
        setAdaptiWarningText("");
        setProductCatalogWarningText("");
        setProductCatalogErrorText("");
        setForeignId("");

        setOrigins([]);
        setMediumResponse({});
        setTokenAdminResponse([]);
        setConsensoResponse([]);
        setAdaptiData({});
        setProductCatalogResponse([]);
        setResetComplete(true);

        setMediumAuditLoading(false);
        setOriginCDNMapperPanelLoading(false);
        setMediumLoading(false);
        setTokenLoading(false);
        setConsensoLoading(false);
        setAdaptiLoading(false);
        console.log("(SundialMasterDetailView.reset) reset complete");
    };

    const disableOriginCDNMapperCheck = () => {
        console.log("(SundialMasterDetailView.disableOriginCDNMapperCheck) disabling");
        setOriginCDNMapperPanelActive(false);
        setOriginCDNMapperError(false);
        setOriginCDNMapperWarning(false);
        setOriginCDNMapperDisabled(true);
        setOriginCDNMapperPanelLoading(false);
    };

    const enableOriginCDNMapperCheck = () => {
        console.log("(SundialMasterDetailView.disableOriginCDNMapperCheck) disabling");
        setOriginCDNMapperDisabled(false);
        setOriginCDNMapperError(false);
        setOriginCDNMapperWarning(false);
        setOriginCDNMapperPanelActive(true);
        setOriginCDNMapperPanelLoading(true);
    };

    const disableMediumCheck = () => {
        console.log("(SundialMasterDetailView.disableMediumCheck) disabling");
        setMediumPanelActive(false);
        setMediumError(false);
        setMediumWarning(false);
        setMediumLoading(false);
    };

    const disableTokenCheck = () => {
        console.log("(SundialMasterDetailView.disableTokenCheck) disabling");
        setTokenPanelActive(false);
        setTokenCompleted(false);
        setTokenDisabled(true);
        setTokenError(false);
        setTokenWarning(false);
        setTokenLoading(false);
    };

    const disableConsensoCheck = () => {
        console.log("(SundialMasterDetailView.disableConsensoCheck) disabling");
        setConsensoPanelActive(false);
        setConsensoCompleted(false);
        setConsensoDisabled(true);
        setConsensoError(false);
        setConsensoWarning(false);
        setConsensoLoading(false);
    };

    const disableAdaptiCheck = () => {
        console.log("(SundialMasterDetailView.disableAdaptiCheck) disabling");
        setAdaptiPanelActive(false);
        setAdaptiCompleted(false);
        setAdaptiDisabled(true);
        setAdaptiError(false);
        setAdaptiWarning(false);
        setAdaptiLoading(false);
        setAdaptiErrorText("");
        setAdaptiWarningText("");
    };

    const disableProductCatalogCheck = () => {
        console.log("(SundialMasterDetailView.disableProductCatalogCheck) disabling");
        setProductCatalogPanelActive(false);
        setProductCatalogCompleted(false);
        setProductCatalogDisabled(true);
        setProductCatalogError(false);
        setProductCatalogWarning(false);
        setProductCatalogLoading(false);
        setProductCatalogErrorText("");
        setProductCatalogWarningText("");
    };

    const queryMediumAudit = async () => {
        setMediumAuditDisabled(false);
        setMediumAuditLoading(true);

        const end = Date.now();
        const start = end - 1000 * 60 * 60 * 24 * 365;
        const mediumAuditPayload = {
            mediaId,
            start,
            end
        };

        const auditResponse = await MediumAuditDataProvider.getData(mediumAuditPayload);
        const auditJson = await auditResponse.json();
        const results = auditJson.results;

        setMediumAuditCompleted(true);
        setMediumAuditLoading(false);

        if (!Array.isArray(results)) {
            console.log("(SundialMasterDetailView.queryMediumAudit) either the results were not an array or their length was 0.");
            setMediumAuditErrorText(`There was an error getting results from Medium Audit for mediaId ${mediaId}.`);
            setMediumAuditError(true);
            setForeignId("");
            setMediumAuditLastUpdate("");
            setBrand("");
            return [];
        }

        if (results.length < 1) {
            console.log("(SundialMasterDetailView.queryMediumAudit) either the results were not an array or their length was 0.");
            setForeignId("");
            setMediumAuditLastUpdate("");
            setBrand("");
            return [];
        }

        const result = results[0];

        if (!result.foreignId) {
            setMediumAuditWarning(true);
        } else {
            setForeignId(result.foreignId);
        }

        console.log("(SundialMasterDetailView.queryMediumAudit) result", result);
        const message = JSON.parse(atob(result.message));
        setMediumAuditResponse(message);
        setMediumAuditLastUpdate(result.lastUpdate);
        console.log("(SundialMasterDetailView.queryMediumAudit) message to decode: ", result.message, "message decoded: ", JSON.parse(atob(result.message)));

        const updatedBrand = message.brand;

        if (updatedBrand) {
            console.log("(SundialMasterDetailView.queryMediumAudit) setting brand to: ", updatedBrand);
            setBrand(updatedBrand);
        }

        return await queryMediumOrigins(brand);
    };

    const queryMediumOrigins = async (brand=brand) => {
        console.log("(SundialMasterDetailView.queryMediumOrigins) brand: ", brand);

        const updatedOrigins = [];
        try {
            const hlsTsOriginsObject = await MediumDataProvider.getOrigins(mediaId, "hls-ts");
            const hlsCmafOriginsObbject = await MediumDataProvider.getOrigins(mediaId, "hls-cmaf");
            console.log("(SundialMasterDetailView.queryMediumOrigins) hls-ts origins: ", hlsTsOriginsObject);
            console.log("(SundialMasterDetailView.queryMediumOrigins) hls-cmaf origins: ", hlsCmafOriginsObbject);

            const hlsTsOriginsArray = getOriginsFromOriginsObject(hlsTsOriginsObject, "hls-ts", brand);
            const hlsCmafOriginsArray = getOriginsFromOriginsObject(hlsCmafOriginsObbject, "hls-cmaf", brand);

            for (const origin of hlsTsOriginsArray) {
                updatedOrigins.push(Object.assign({}, origin, {brand}));
            }
            for (const origin of hlsCmafOriginsArray) {
                updatedOrigins.push(Object.assign({}, origin, {brand}));
            }

            if (updatedOrigins.length > 0) {
                console.log("(SundialMasterDetailView.queryMediumOrigins) enabling origin-cdn mapper check");
                enableOriginCDNMapperCheck();
            } else {
                console.log("(SundialMasterDetailView.queryMediumOrigins) disabling origin-cdn mapper check");
                disableOriginCDNMapperCheck();
            }
        } catch (e) {
            console.error(e);
            disableOriginCDNMapperCheck();
            disableMediumCheck();
            disableTokenCheck();
            disableConsensoCheck();
            disableAdaptiCheck();
            disableProductCatalogCheck();
        }
        console.log("(SundialMasterDetailView.queryMediumOrigins) updated origins: ", updatedOrigins);
        return updatedOrigins;
    };

    const getOriginsFromOriginsObject = (originsObject, originType, brand="") => {
        if (originsObject.hasOwnProperty("error")) {
            console.log("(SundialMasterDetailView.getOriginsFromOriginsObject) origins object had an error: ", originsObject.error);
            return [];
        }

        const allOrigins = [];
        for (const platform of Object.keys(originsObject)) {
            for (const drm of Object.keys(originsObject[platform])) {
                if (Array.isArray(originsObject[platform][drm])) {
                    for (const drmLevel of originsObject[platform][drm]) {
                        if (drmLevel.hasOwnProperty("urlObj") && drmLevel.urlObj.hasOwnProperty("host")) {
                            console.log("(SundialMasterDetailView.getOriginsFromOriginsObject) drm level: ", drmLevel);
                            allOrigins.push(drmLevel);
                        }
                    }
                } else {
                    for (const drmLevel of Object.keys(originsObject[platform][drm])) {
                        if (originsObject[platform][drm][drmLevel].hasOwnProperty("urlObj") && originsObject[platform][drm][drmLevel].urlObj.hasOwnProperty("host")) {
                            console.log("(SundialMasterDetailView.getOriginsFromOriginsObject) drm level: ", originsObject[platform][drm][drmLevel]);
                            allOrigins.push(drmLevel);
                        } else if (typeof originsObject[platform][drm][drmLevel] === "object") {
                            for (const encryptionTypeLevel of Object.keys(originsObject[platform][drm][drmLevel])) {
                                console.log("(SundialMasterDetailView.getOriginsFromOriginsObject) encryption type level: ", originsObject[platform][drm][drmLevel][encryptionTypeLevel]);
                                allOrigins.push(originsObject[platform][drm][drmLevel][encryptionTypeLevel]);
                            }
                        }
                    }
                }
            }
        }

        const originsArray = [];

        for (const origin of allOrigins) {
            if (origin.hasOwnProperty("urlObj") && origin.urlObj.hasOwnProperty("host")) {
                const host = origin.urlObj.host;
                let hostFound = false;
                for (const existingOrigin of originsArray) {
                    if (host === existingOrigin.origin.originUrl) {
                        hostFound = true;
                        break;
                    }
                }
                if (!hostFound) {
                    const origin = {
                        originType,
                        brand,
                        originUrl: host
                    }
                    console.log("(SundialMasterDetailView.getOriginsFromOriginsObject) pushing origin: ", origin)
                    originsArray.push({origin});
                }
            }
        }
        if (originsArray.length > 0) {
            enableOriginCDNMapperCheck();
        }

        console.log("(SundialMasterDetailView.getOriginsFromOriginObject) origins array: ", originsArray);
        return originsArray;
    };

    const queryTokenAssets = async () => {
        setTokenDisabled(false);

        const body = {
            action: "GET",
            assetIds: [],
            mediaIds: [mediaId],
            odtAiringIds: []
        };

        const tokenAssets = await TokenAdminDataProvider.getAssets(body);
        const tokenJson = await tokenAssets.json();
        const results = tokenJson.byMediaIds;

        console.log("(SundialMasterDetailView.queryTokenAssets) results: ", results);

        if (!Array.isArray(results) || results.length < 1) {
            setTokenAdminResponse([]);
        } else {
            setTokenAdminResponse(results);
        }

        return results;
    };

    const queryConsensoAssets = async () => {
        setConsensoDisabled(false);

        return await ConsensoDataProvider.getAssets([mediaId]).then(response => {
            console.log("(SundialMasterDetailView.queryConsensoAssets) results: ", response);
            if (response.hasOwnProperty("error")) {
                setConsensoError(true);
                return [];
            }

            setConsensoResponse(response.results);
            return response.results;
        });
    };

    const queryAdapti = async brand => {
        setAdaptiDisabled(false);
        return await AdaptiDataProvider.getAssetsForBrandAndMediaId(brand, mediaId).then(response => {
            console.log("(SundialMasterDetailView.runAdaptiCheck) response from adapti: ", response);
            if (response.hasOwnProperty("org")) {
                setOrg(response.org);
            } else {
                setOrg("");
            }

            if (!Array.isArray(response.assets)) {
                setAdaptiError(true);
                setAdaptiErrorText("The response from Adapti had an unexpected format.");
                return;
            }

            if (response.assets.length < 1) {
                setAdaptiWarning(true);
                setAdaptiWarningText(`Adapti returned no assets for the brand ${brand}.`);
                return;
            }

            for (const asset of response.assets) {
                if (asset.mediaId === mediaId) {
                    setAdaptiData(Object.assign({}, asset));
                    return;
                }
            }
        });
    };

    const getTokenProducts = () => {
        const products = new Set();

        console.log("(SundialMasterDetailView.getTokenProducts) token response: ", tokenAdminResponse);

        for (const asset of tokenAdminResponse) {
            if (Array.isArray(asset.flights)) {
                for (const flight of asset.flights) {
                    console.log("(SundialMasterDetailView.getTokenProducts) flight: ", flight);
                    if (Array.isArray(flight.products)) {
                        for (const product of flight.products) {
                            products.add(product);
                        }
                    }
                }
            }
        }

        console.log("(SundialMasterDetailView.getTokenProducts) products extracted from the token response: ", products);

        return Array.from(products);
    };

    const queryProductCatalog = async () => {
        console.log("(SundialMasterDetailView.queryProductCatalog): beginning query of product catalog for brand ", normalizedBrand);
        const tokenProducts = getTokenProducts();
        console.log("(SundialMasterDetailView.getTokenProducts) products extracted from the token response: ", tokenProducts);

        if (tokenProducts.length < 1) {
            disableProductCatalogCheck();
            return;
        }

        const productCatalogProducts = [];

        await ProductCatalogDataProvider.getProductsForBrand(normalizedBrand).then(response => {
            console.log("(SundialMasterDetailView.queryProductCatalog) response from the product catalogue: ", response);

            if (response.hasOwnProperty("errors")) {
                setProductCatalogError(true);
                setProductCatalogErrorText("There has been an unexpected error querying the Product Catalog.");
                return;
            }

            if (response.hasOwnProperty("products")) {
                for (const product of response.products) {
                    // temporary
                    for (const key of Object.keys(product.storefrontProducts)) {
                        for (const storefrontProduct of product.storefrontProducts[key]) {
                            for (const tokenProduct of tokenProducts) {
                                if (tokenProduct === storefrontProduct.sku) {
                                    productCatalogProducts.push(product);
                                    break;
                                }
                            }
                        }
                    }

                    if (product.hasOwnProperty("universalProductIndicator") && tokenProducts.includes(product.universalProductIndicator)) {
                        productCatalogProducts.push(product);
                    }
                }
            } else {
                setProductCatalogError(true);
            }
        });

        console.log("(SundialMasterDetailView.queryProductCatalog) product catalog products: ", productCatalogProducts);
        setProductCatalogResponse(productCatalogProducts);
        return productCatalogProducts;
    };

    return (
        <Grid>
            <Grid.Row>
                <Container width={16} textAlign="center" className="masterContainer" fluid>
                    <Grid.Row>
                        <Form>
                            <Form.Group inline>
                                <Form.Input
                                    label="Media&nbsp;ID"
                                    width={13}
                                    inline
                                    value={mediaId}
                                    onChange={(event, {value}) => setMediaId(value)}
                                />
                                <Form.Button
                                    content="Check Media ID"
                                    disabled={mediaId === ""}
                                    onClick={() => setInvestigateClicked(true)}
                                />
                            </Form.Group>
                        </Form>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row>
                        <SundialInvestigationStepGroup
                            mediumAuditDisabled={mediumAuditDisabled}
                            mediumAuditError={mediumAuditError}
                            mediumAuditCompleted={mediumAuditCompleted}
                            mediumAuditWarning={mediumAuditWarning}
                            originCDNMapperDisabled={originCDNMapperDisabled}
                            originCDNMapperError={originCDNMapperError}
                            originCDNMapperCompleted={originCDNMapperCompleted}
                            originCDNMapperWarning={originCDNMapperWarning}
                            mediumDisabled={mediumDisabled}
                            mediumError={mediumError}
                            mediumCompleted={mediumCompleted}
                            mediumWarning={mediumWarning}
                            tokenDisabled={tokenDisabled}
                            tokenError={tokenError}
                            tokenCompleted={tokenCompleted}
                            tokenWarning={tokenWarning}
                            consensoDisabled={consensoDisabled}
                            consensoError={consensoError}
                            consensoCompleted={consensoCompleted}
                            consensoWarning={consensoWarning}
                            adaptiDisabled={adaptiDisabled}
                            adaptiError={adaptiError}
                            adaptiCompleted={adaptiCompleted}
                            adaptiWarning={adaptiWarning}
                            productCatalogDisabled={productCatalogDisabled}
                            productCatalogError={productCatalogError}
                            productCatalogCompleted={productCatalogCompleted}
                            productCatalogWarning={productCatalogWarning}
                        />
                        <Accordion
                            hidden={!showAccordion}
                            styled
                            fluid
                            exclusive={false}
                            panels={[
                                <MediumAuditAccordionPanel
                                    key="medium-audit-accordion-panel"
                                    active={mediumAuditPanelActive}
                                    mediumAuditCompleted={mediumAuditCompleted}
                                    mediumAuditResponse={mediumAuditResponse}
                                    lastUpdate={mediumAuditLastUpdate}
                                    error={mediumAuditErrorText}
                                    foreignId={foreignId}
                                    onTitleClick={() => setMediumAuditPanelActive(!mediumAuditPanelActive)}
                                    dateTimeFormat={DATE_TIME_FORMAT}
                                    loading={mediumAuditLoading}
                                    mediaId={frozenMediaId}
                                />,
                                <OriginCDNMapperAccordionPanel
                                    key="origin-cdn-mapper-accordion-panel"
                                    origins={origins}
                                    active={originCDNMapperPanelActive}
                                    setOriginCDNMapperError={setOriginCDNMapperError}
                                    onTitleClick={() => setOriginCDNMapperPanelActive(!originCDNMapperPanelActive)}
                                    user={props.user}
                                    loading={originCDNMapperPanelLoading}
                                    setLoading={setOriginCDNMapperPanelLoading}
                                    setCompleted={setOriginCDNMapperCompleted}
                                    brand={brand}
                                />,
                                <MediumAccordionPanel
                                    key="medium-accordion-panel"
                                    active={mediumPanelActive}
                                    mediumCompleted={mediumCompleted}
                                    mediumResponse={mediumResponse}
                                    mediumError={mediumError}
                                    mediumWarning={mediumWarning}
                                    mediaId={mediaId}
                                    onTitleClick={() => setMediumPanelActive(!mediumPanelActive)}
                                    dateTimeFormat={DATE_TIME_FORMAT}
                                    loading={mediumLoading}
                                />,
                                <ConsensoAccordionPanel
                                    key="consenso-accordion-panel"
                                    active={consensoPanelActive}
                                    consensoCompleted={consensoCompleted}
                                    consensoResponse={consensoResponse}
                                    setConsensoError={setConsensoError}
                                    consensoError={consensoError}
                                    consensoWarning={consensoWarning}
                                    mediaId={mediaId}
                                    onTitleClick={() => setConsensoPanelActive(!consensoPanelActive)}
                                    dateTimeFormat={DATE_TIME_FORMAT}
                                    loading={consensoLoading}
                                />,
                                <TokenAccordionPanel
                                    key="token-accordion-panel"
                                    active={tokenPanelActive}
                                    tokenAdminResponse={tokenAdminResponse}
                                    setTokenError={setTokenError}
                                    tokenError={tokenError}
                                    mediaId={frozenMediaId}
                                    onTitleClick={() => setTokenPanelActive(!tokenPanelActive)}
                                    dateTimeFormat={DATE_TIME_FORMAT}
                                    loading={tokenLoading}
                                />,
                                <AdaptiAccordionPanel
                                    key="adapti-accordion-panel"
                                    active={adaptiPanelActive}
                                    adaptiData={adaptiData}
                                    adaptiError={adaptiError}
                                    adaptiErrorText={adaptiErrorText}
                                    adaptiWarning={adaptiWarning}
                                    adaptiWarningText={adaptiWarningText}
                                    org={org}
                                    mediaId={mediaId}
                                    onTitleClick={() => setAdaptiPanelActive(!adaptiPanelActive)}
                                    loading={adaptiLoading}
                                />,
                                <ProductCatalogAccordionPanel
                                    key="product-catalog-accordion-panel"
                                    active={productCatalogPanelActive}
                                    productCatalogResponse={productCatalogResponse}
                                    productCatalogError={productCatalogError}
                                    productCatalogErrorText={productCatalogErrorText}
                                    productCatalogWarning={productCatalogWarning}
                                    productCatalogWarningText={productCatalogWarningText}
                                    onTitleClick={() => setProductCatalogPanelActive(!productCatalogPanelActive)}
                                    loading={productCatalogLoading}
                                />
                            ]}
                        />
                    </Grid.Row>
                </Container>
            </Grid.Row>
        </Grid>
    );
};
