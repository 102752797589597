import React, {useState, useEffect} from "react";
import {Accordion, Container, Grid, Message, Icon, Dimmer, Loader, Table, Button} from "semantic-ui-react";
import {Link} from "react-router-dom";

const DRM_TYPES = ["fairplay", "widevine", "playready", "primetime", "unprotected", "preview", "bulkaes", "multidrm"];
const PLATFORMS = ["", "tv", "desktop", "phone", "tablet"];

export default function MediumAccordionPanel(props) {
    const [errorText, setErrorText] = useState("");
    const [mediumV1url, setMediumV1url] = useState("");
    const [mediumV2url, setMediumV2url] = useState("");
    const [lastUpdate, setLastUpdate] = useState("");
    const [mediaType, setMediaType] = useState("");
    const [platformDRM, setPlatformDRM] = useState({});

    useEffect(function setErrorTextFromResponse() {
        if (props.mediumResponse && props.mediumResponse.error) {
            console.log("(MediumAccordionPanel.setErrorTextFromResponse) error encountered: ", props.mediumResponse);
            setErrorText(props.mediumResponse.error.error || props.mediumResponse.error.message || JSON.stringify(props.mediumResponse.error));
        }
    }, [JSON.stringify(props.mediumResponse.error)]);

    useEffect(function setPlatformDRMFromMedium() {
        let platformDRM = [];
        platformDRM.push(PLATFORMS);
        for (const drm of DRM_TYPES) {
            const drmRow = [drm];
            for (let i = 1, platform = PLATFORMS[i]; i < PLATFORMS.length; i++) {
                const hasPlatformDRM = props.mediumResponse && props.mediumResponse.media && props.mediumResponse.media.hasOwnProperty(platform) && props.mediumResponse.media[platform].hasOwnProperty((drm));
                drmRow.push(hasPlatformDRM);
            }
            platformDRM.push(drmRow);
        }
        setPlatformDRM(platformDRM);
        console.log("(MediumAccordionPanel.setPlatformDRMFromMedium) platformDRM:", platformDRM);
    }, [JSON.stringify(props.mediumResponse.media)]);

    useEffect(function setFromMediumResponse() {
        if (props.mediumResponse && props.mediumResponse.type) {
            setMediaType(props.mediumResponse.type);
        } else {
            setMediaType("");
        }
        if (props.mediumResponse && props.mediumResponse.lastUpdate) {
            const lastUpdateDate = new Date(props.mediumResponse.lastUpdate);
            setLastUpdate(props.dateTimeFormat.format(lastUpdateDate));
        } else {
            setLastUpdate("");
        }
        setMediumV1url(`${process.env.MEDIUM_EXTERNAL_URL}/media/${props.mediumResponse && props.mediumResponse.mediaId ? props.mediumResponse.mediaId : props.mediaId}`);
        setMediumV2url(`${process.env.MEDIUM_EXTERNAL_URL}/v2/media/${props.mediumResponse && props.mediumResponse.mediaId ? props.mediumResponse.mediaId : props.mediaId}?appId=${process.env.SYNAPSE_APP_ID}`);
    }, [JSON.stringify(props.mediumResponse)]);
    return (
        <Accordion.Panel
            onTitleClick={props.onTitleClick}
            active={props.active}
            title="Medium"
            as={Container}
            content={
                <Accordion.Content>
                    <Dimmer.Dimmable dimmed={props.loading}>
                        {
                            props.loading ? <p><br /><br /></p> : ""
                        }
                        <Dimmer active={props.loading}><Loader indeterminate>Retrieving V2 Media JSON</Loader></Dimmer>
                        <Grid textAlign="left">
                            <Grid.Row>
                                <Grid.Column textAlign="left" width={16}>
                                    {
                                        props.mediumError ?
                                            <Message icon error hidden={errorText === ""}>
                                                <Icon name="exclamation"/>
                                                <Message.Content>
                                                    <Message.Header>Error</Message.Header>
                                                    {errorText}
                                                </Message.Content>
                                            </Message> :
                                            props.mediumWarning ?
                                                <Message icon color="yellow" visible={false}>
                                                    <Icon name="warning" />
                                                    <Message.Content>
                                                        <Message.Header>Warning</Message.Header>
                                                        warning
                                                    </Message.Content>
                                                </Message> : ""
                                    }
                                    <Table celled>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>
                                                    <Button primary fluid as={Link} to={{pathname: mediumV1url}} target="_blank" content="Medium V1" />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Button primary fluid as={Link} to={{pathname: mediumV2url}} target="_blank" content="Medium V2" />
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    {
                                        props.mediumResponse && (mediaType || lastUpdate) ?
                                            <Container fluid>
                                                <Table celled compact>
                                                    <Table.Body>
                                                        {
                                                            mediaType ? <Table.Row><Table.Cell width={2}><b>Media Type</b></Table.Cell><Table.Cell width={14}>{mediaType}</Table.Cell></Table.Row> : ""
                                                        }
                                                        {
                                                            lastUpdate ? <Table.Row><Table.Cell><b>Last Updated</b></Table.Cell><Table.Cell>{lastUpdate}</Table.Cell></Table.Row> : ""
                                                        }
                                                    </Table.Body>
                                                </Table>
                                                <Table
                                                    compact
                                                    celled
                                                    definition
                                                    tableData={platformDRM}
                                                    renderBodyRow={(data, rowIndex) =>
                                                        <Table.Row>
                                                            {
                                                                rowIndex === 0 ?
                                                                    data.map(cell =>
                                                                        <Table.Cell>
                                                                            <b>{cell.toString()}</b>
                                                                        </Table.Cell>) :
                                                                    data.map((cell, columnIndex) =>
                                                                        <Table.Cell>
                                                                            {columnIndex === 0 ? <b>{cell.toString()}</b> : <Icon name={cell ? "check mark" : "x"} />}
                                                                        </Table.Cell>)
                                                            }
                                                        </Table.Row>
                                                    }
                                                />
                                            </Container> : ""
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Dimmer.Dimmable>
                </Accordion.Content>
            }
        />
    );
};
